<template>
  <div class="TicketRefundRatio">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn">
          <el-button type="primary" @click="insertRefund" plain
            >新增退款比例</el-button
          >
        </div>
      </div>
      <div class="main-card">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="550"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column label="描述">
            <template slot-scope="scope">
              <span>{{ scope.row.description }}</span>
            </template>
          </el-table-column>
          <el-table-column label="序号">
            <template slot-scope="scope">
              <span>{{ scope.row.sort }}</span>
            </template>
          </el-table-column>
          <el-table-column label="类型">
            <template slot-scope="scope">
              <span>{{ optionList[scope.row.endType] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="小时">
            <template slot-scope="scope">
              <span>{{ scope.row.endDays }}</span>
            </template>
          </el-table-column>
          <el-table-column label="退款比例">
            <template slot-scope="scope">
              <span>{{ scope.row.refundProportion + "%" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="备注">
            <template slot-scope="scope">
              <span>{{ scope.row.remark }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page-card">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </el-card>
    <el-dialog
      title="新增退款比例"
      :visible.sync="dialogFormVisible"
      width="30%"
    >
      <el-form ref="form" :model="form" label-width="100px" :rules="rules">
        <el-form-item label="描述" prop="description">
          <el-input v-model="form.description"></el-input>
        </el-form-item>
        <el-form-item label="序号" prop="sort">
          <el-input v-model="form.sort"></el-input>
        </el-form-item>
        <el-form-item label="日期类型" prop="endType">
          <el-select
            v-model="form.endType"
            placeholder="请选择日期类型"
            style="width: 250px"
          >
            <el-option
              v-for="item in optionList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="endDays">
          <el-input v-model="form.endDays" style="width: 100px"></el-input
          >&nbsp;小时
        </el-form-item>
        <el-form-item label="退款百分比" prop="refundProportion">
          <el-input
            v-model="form.refundProportion"
            style="width: 200px"
          ></el-input
          >&nbsp;%
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="pushGame">提交</el-button>
      </div>
    </el-dialog>
    <el-dialog title="编辑退款比例" :visible.sync="dialogEditVisible">
      <el-form
        ref="editForm"
        :model="editForm"
        label-width="100px"
        :rules="rules"
      >
        <div>
          <el-form-item label="描述" prop="description">
            <el-input v-model="editForm.description"></el-input>
          </el-form-item>
          <el-form-item label="序号" prop="sort">
            <el-input v-model="editForm.sort"></el-input>
          </el-form-item>
          <el-form-item label="日期类型" prop="endType">
            <el-select
              v-model="editForm.endType"
              placeholder="请选择日期类型"
              style="width: 250px"
            >
              <el-option
                v-for="item in optionList"
                :label="item.label"
                :value="item.value"
                :key="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="endDays">
            <el-input v-model="editForm.endDays" style="width: 100px"></el-input
            >&nbsp;小时
          </el-form-item>
          <el-form-item label="退款百分比" prop="refundProportion">
            <el-input
              v-model="editForm.refundProportion"
              style="width: 200px"
            ></el-input
            >&nbsp;%
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="editForm.remark"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="pushEdit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      optionList: [
        // {
        //   value: 0,
        //   label: "报名截止前",
        // },
        // {
        //   value: 1,
        //   label: "赛前",
        // },
        {
          value: 2,
          label: "入住日期之前",
        },
      ],
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      dialogEditVisible: false,
      dialogFormVisible: false,
      form: {},
      editForm: {},
      rules: {
        description: [
          { required: true, message: "请输入描述", trigger: "blur" },
        ],
        sort: [{ required: true, message: "请输入序号", trigger: "blur" }],
        endType: [
          { required: true, message: "请选择日期类型", trigger: "blur" },
        ],
        endDays: [{ required: true, message: "请输入小时", trigger: "blur" }],
        refundProportion: [
          { required: true, message: "请输入退款百分比", trigger: "blur" },
        ],
        remark: [{ required: true, message: "请输入备注", trigger: "blur" }],
      },
    };
  },
  methods: {
    insertRefund() {
      this.dialogFormVisible = true;
      this.form = {};
    },

    pushGame() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.dialogFormVisible = false;
          this.form.competitionId = this.id;
          this.form.type = 2;
          try {
            const result = await this.$api.insertRefund(this.form);
            if (result.data.status_code == "200") {
              this.$message({
                type: "success",
                message: "添加成功",
              });
              this.findRefundProportionList();
            } else if (result.data.status_code == "1002") {
              this.$message({
                type: "warning",
                message: result.data.msg,
              });
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //编辑
    handleEdit(index, row) {
      this.editForm = JSON.parse(JSON.stringify(row));
      this.editForm.competitionId = this.id;
      // this.editForm.id = row.id
      this.dialogEditVisible = true;
      // this.editForm.description = row.description
      // this.editForm.sort = row.sort
      // this.editForm.endType = row.endType
      // this.editForm.endDays = row.endDays
      // this.editForm.refundProportion = row.refundProportion
      // this.editForm.remark = row.remark
    },
    handleDelete(index) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          const result = await this.$api.deleteRefund(`id=${index}`);
          if (result.data.status_code == "200") {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.findRefundProportionList();
          } else if (result.data.status_code == "1002") {
            this.$message({
              type: "warning",
              message: result.data.msg,
            });
          }
        } catch (error) {
          console.log(error);
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.findRefundProportionList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.findRefundProportionList();
    },
    pushEdit() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          this.dialogEditVisible = false;
          delete this.editForm.createTime;
          delete this.editForm.updateTime;
          try {
            const result = await this.$api.editRefund(this.editForm);
            if (result.data.status_code == "200") {
              this.$message({
                type: "success",
                message: "修改成功",
              });
              this.findRefundProportionList();
            } else if (result.data.status_code == "1002") {
              this.$message({
                type: "warning",
                message: result.data.msg,
              });
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async findRefundProportionList() {
      try {
        const result = await this.$api.findRefundProportionList(
          `currentPage=${this.currentPage}&pageSize=${this.pageSize}&search_EQ_competitionId=${this.id}&search_EQ_type=2`
        );
        this.tableData = result.pageResults;
        this.totalCount = result.totalCount;
        console.log(this.tableData);
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.findRefundProportionList();
  },
};
</script>

<style lang="less">
.TicketRefundRatio {
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn {
        display: flex;
        // float: right;
        .select {
          width: 100px;
          margin-right: 20px;
        }
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .main-card {
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .el-col-11 {
    width: 20%;
  }
  .el-col-2 {
    width: 1.3%;
  }
}
</style>
