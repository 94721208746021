<template>
  <div class="RefundRatio">
    <el-page-header
      @back="goBack"
      :content="nav"
      title=""
      style="margin-bottom: 10px"
    >
    </el-page-header>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="报名退款比例" name="1">
        <Bar1></Bar1>
      </el-tab-pane>
      <el-tab-pane label="门票退款比例" name="2">
        <Bar2 v-if="tab.name == '2'"></Bar2>
      </el-tab-pane>
      <el-tab-pane label="酒店退款比例" name="3">
        <Bar3 v-if="tab.name == '3'"></Bar3>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Bar1 from "../../components/Registrationrefund.vue";
import Bar2 from "../../components/TicketRefundRatio.vue";
import Bar3 from "../../components/hotelRefund.vue";
export default {
  data() {
    return {
      nav: this.$route.params.back,
      id: this.$route.params.id,
      activeName: "1",
      tab: {},
      name: "",
    };
  },
  components: {
    Bar1,
    Bar2,
    Bar3,
  },
  mounted() {
    this.updateType();
  },
  methods: {
    updateType() {
      let type = this.$route.params.name;
      //通过拿到的值不同，更改activeName的值
      if (type == "1") {
        this.tab.name = "1";
        this.activeName = "1";
      } else if (type == "2") {
        this.tab.name = "2";
        this.activeName = "2";
      } else if (type == "3") {
        this.tab.name = "3";
        this.activeName = "3";
      }
    },
    handleClick(tab, event) {
      // console.log(tab, event)
      this.tab = tab;
      if (tab.name == "1") {
        this.name = 1;
      } else if (tab.name == "2") {
        this.name = 2;
      } else if (tab.name == "3") {
        this.name = 3;
      }
      this.$router.push({
        name: "RefundRatio",
        params: {
          back: this.nav,
          id: this.id,
          name: this.name,
          pageSize: 10,
          currentPage: 1,
        },
      });
    },
    goBack() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style lang="less"></style>
